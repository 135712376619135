import React from "react";
import Layout from "../layout/Layout";
import CompareProducts from "../components/products/CompareProducts";

function compare() {
  return (
    <Layout>
      <CompareProducts />
    </Layout>
  );
}

export default compare;
